import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { SelectedItemsNav } from '../../../interfaces/auddineceAccessInterfaces/audiencesTypes'

const initialState: SelectedItemsNav = {
  projectId: undefined,
  sampleId: undefined,
  audienceId: undefined,
  batchId: undefined
}

const audiencesNavigationDataSlice = createSlice({
  name: 'audiencesNavigation',
  initialState,
  reducers: {
    editAudiencesNavigation(state, action: PayloadAction<SelectedItemsNav>) {
      const updatedState = { ...state, projectId: action.payload.projectId, sampleId: action.payload.sampleId, audienceId: action.payload.audienceId, batchId: action.payload.batchId }
      return updatedState
    }
  }
})

export const {
  editAudiencesNavigation
} = audiencesNavigationDataSlice.actions
export default audiencesNavigationDataSlice.reducer