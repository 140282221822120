import type { Targeting } from "../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes"

export const formatFormData = (formData: { [k: string]: FormDataEntryValue }, etag: string | null, targeting: Targeting | null) => {
  return {
    displayName: formData.batchDisplayName,
    language: formData.batchLanguage,
    country: formData.batchCountry,
    currency: formData.currency,
    targetType: formData.targetType,
    launchDate: formData.launchDate,
    targetEndDate: formData.targetEndDate,
    quantity: Number(formData.quantity),
    lengthOfInterview: Number(formData.lengthOfInterview),
    incidenceRate: Number(formData.incidenceRate),
    clientCPI: Number(formData.clientCPI),
    cpi: Number(formData.cpi),
    completeAsap: formData.completeAsap === "true",
    integrationPartners: [],
    targeting: targeting,
    etag: etag ? etag : null,
  }
}