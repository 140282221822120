import { Fragment, useState } from 'react'

import { Icon } from '../../../shared/Icon/Icon'
import type { ReconciliationData, ReconciliationDataSlim } from '../../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces'

interface Props {
  reconciliationDataSlim?: ReconciliationDataSlim
  reconciliationData?: ReconciliationData,
  isLoading: boolean
  handleNewReconcile: () => void;
  handleExport: () => void;
}

export const ReconcileTable = ({ reconciliationData, isLoading, handleNewReconcile, handleExport, reconciliationDataSlim }: Props) => {
  const [searchInput, setSearchInput] = useState<string>("")

  return (
    <Fragment>
      <div className='d-flex align-items-center w-100 h-32 justify-content-between' >
        <p className="innerboard-header d-flex align-items-center stronger h-32 m-0">Reconcile</p>
        <div className='d-flex justify-content-between' >
          <div className="input-group mr-2 h-32">
            <input
              type="text"
              value={searchInput}
              className="form-control search-input"
              placeholder={"Search"}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <button type="button" className='btn btn-icon icon-l btn-shadow mr-2 flex-shrink-0' disabled={reconciliationData?.status === "Preview"} onClick={() => handleExport()} >
            <Icon type="help-external" />
            Export
          </button>
          <button style={{ whiteSpace: "nowrap" }} className="btn btn-primary btn-icon icon-l flex-shrink-0" type="button" id="newReconciliation" onClick={() => handleNewReconcile()}>
            <Icon type="add" />
            New reconciliation
          </button>
        </div>
      </div>
      <div className='board-content-container reconcile-content d-flex justify-content-center align-items-center h-100 overflow-y'>
        <div className="table target-table mb-0 pb-0 h-100">
          <p className='text-muted bg-white border-bottom px-3 py-3 m-0 position-sticky'>Name</p>
          {
            reconciliationDataSlim &&
            <div className='w-100 border-bottom h-48'>
              <div className="d-flex align-items-center justify-content-between px-2 py-3">
                <p className='m-0 text-primary pl-2 text-start strong p-0 m-0'>{reconciliationDataSlim.name}</p>
                <p className='alert py-1 px-2 m-0 span-default small'>
                  {reconciliationDataSlim.status}
                </p>
              </div>
            </div>
          }
          <div className={typeof (reconciliationData?.reconciliations) !== "undefined" ? 'd-flex flex-column' : 'd-flex flex-column h-75'}>
            {typeof (reconciliationData?.reconciliations) !== "undefined" ?
              reconciliationData.reconciliations.filter(item => item.name ? item.name.toLowerCase().includes(searchInput.toLowerCase()) : item).map((item, index: number) => (
                <div key={item.id} className="accordion" id="accordionReconcile">
                  <div className="accordion-item">
                    <div className="reconcile-table mb-0 ">
                      <div className="accordion-header w-100" id={`heading${index}`} data-toggle="collapse" data-target={`#main${index}`} aria-expanded="false" aria-controls="main">
                        <div className='d-flex align-items-center justify-content-between border-bottom w-100 px-2 py-3 cursor-pointer'>
                          <p style={{ textAlign: "left" }} className='text-primary pl-2 text-start strong p-0 m-0' >{item.name ? item.name : "-"}</p>
                          <div className='d-flex justify-content-between row pr-4' >
                            <div className='align-self-center mr-5'>
                              <span className="alert py-1 px-2 m-0 mr-4 span-default small">{item.ignoredCount} terminates</span>
                              <span className="alert py-1 px-2 m-0 span-success small">{item.successCount} accepted completes</span>
                            </div>
                            <Icon type="chevron-mini-down-alt" className="submenu" />
                          </div>
                        </div>
                      </div>
                      <div
                        id={`main${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index}`}
                        data-parent="#accordionReconcile">
                        <table className="w-100 mb-0 ">
                          <thead >
                            <tr >
                              <th style={{ width: "30%" }} className='text-left text-muted font-weight-normal' scope="col">WalrID</th>
                              <th style={{ width: "30%" }} className='text-left text-muted font-weight-normal' scope="col">Supplier RID </th>
                              <th className='text-left text-muted font-weight-normal' scope="col">Supplier</th>
                              <th className='text-left text-muted font-weight-normal' scope="col">Gatekepeer Status</th>
                              <th className='text-left text-muted font-weight-normal' scope="col">Reconciled status</th>
                            </tr>
                          </thead>
                          <tbody >
                            {item.respondents.map((respondents) => (
                              <tr key={respondents.id}>
                                <td className='text-left text-primary border-top border-secondary p-3'>{respondents.id}</td>
                                <td className='text-left text-primary border-top border-secondary p-3'>{respondents.rid ? respondents.rid : "-"}
                                </td>
                                <td className='text-left text-primary border-top border-secondary p-3'>{respondents.platform ? respondents.platform : "-"}</td>
                                <td className='text-left text-primary border-top border-secondary p-3'>{respondents.status ? respondents.status : "Not Found"}</td>
                                <td className='text-left text-primary border-top border-secondary p-3'>{respondents.reconciliationStatus ? respondents.reconciliationStatus : "Unable to reconcile"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )) :
              <>
                {
                  isLoading ?
                    <div className='h-100 d-flex align-items-center justify-content-center'>
                      <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" /></div>
                    :
                    <div className='h-100 text-muted d-flex align-items-center justify-content-center'>No reconciled IDs found</div>
                }
              </>
            }
          </div>
        </div>
      </div >
    </Fragment>
  )
}