import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import usePrevious from '../shared/customHooks/usePrevious';
import { useDebounce } from '../shared/customHooks/useDebounce';
import { DropdownButton } from '../shared/DropdownButton/DropdownButton';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { GateKeeperSupplierModal } from './GateKeeperSupplierModal/GateKeeperSupplierModal';
import { GateKeeperNewSupplierModal } from './GateKeeperNewSupplierModal/GateKeeperNewSupplierModal';
import { SaveSupplierSuccessModal } from './GateKeeperNewSupplierModal/SaveSupplierSuccessModal/SaveSupplierSuccessModal';
import type { BatchUrlsFormData, BatchVariables, DeletePromptGK, GKProjectToExclude, GatekeeperNewFormData, GkAddSuppliers, GkSuppliersData, Market, ProjectStateHandler, ReconciliationGkData } from '../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces';
import { Icon } from '../shared/Icon/Icon';
import type { ModalData } from '../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces';
import { GKNewReconciliationModal } from './GKNewReconciliationModal/GKNewReconciliationModal';
import { GKOverviewReconciliationModal } from './GKOverviewReconciliationModal/GKOverviewReconciliationModal';
import { GKPreviewReconciliationModal } from './GKPreviewReconciliationModal/GKPreviewReconciliationModal';
import type { ReconciliationData } from '../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces';
import { GKCreateNewProject } from './GKCreateNewProjectModal/GKCreateNewProject';
import { fetchGetJson as getProjects, fetchGetRes, fetchGetJson as getSuppliers, fetchPostResOrJson, fetchDelete, fetchGetJson, fetchPostFile, fetchGetBlob, fetchGetJson as getSupportedMarkets } from '../../services/services';
import { BatchURLsModal } from './GKCreateNewProjectModal/BatchURLsModal/BatchURLsModal';
import { MassActionPanel } from './MassActionPanel/MassActionPanel';
import { SuppliersStatsModal } from './SuppliersStatsModal/SuppliersStatsModal';
import { DeleteProptModal } from './DeleteProptModal/DeleteProptModal';
import { RecontactModal } from './RecontactModal/RecontactModal';
import fileManagerDownloadHandler from '../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler';
import WidSearchModal from './WidSearchModal/WidSearchModal';
import type { RootState } from '../../store/reducers/rootReducer';
import { formatDate } from '../shared/DateUtils/DateUtils';

let formDataInit = {
  displayName: '',
  country: { name: "", countryCode: "", languageCode: "" },
  loi: "",
  surveyTestUrl: '',
  surveyLiveUrl: '',
  useResearchDefender: true,
  wave: "",
  exclusion: null,
  countryCode: "",
  allowReentry: false,
  redirectCaptureParameter: "",
  recontactIds: null
}

export const Gatekeeper = () => {
  document.title = "Gatekeeper – Walr";
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [didMount, setDidMount] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<TODO[] | null>(null)
  const [gkProjectsToExclude, setGKProjectsToExclude] = useState<GKProjectToExclude[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [loadMoreToken, setLoadMoreToken] = useState<string>("")
  const [showSupplierModal, setShowSupplierModal] = useState<boolean>(false)
  const [showNewSupplierModal, setShowNewSupplierModal] = useState<boolean>(false)
  const [supplierSuccessModal, setSupplierSuccessModal] = useState<boolean>(false)
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showCreateReconciliationModal, setShowCreateReconciliationModal] = useState<boolean>(false)
  const [showOverviewReconciliationModal, setShowOverviewReconciliationModal] = useState<boolean>(false)
  const [showPreviewReconciliationModal, setShowPreviewReconciliationModal] = useState<boolean>(false)
  const [showCreateNewProject, setShowCreateNewProject] = useState<ProjectStateHandler>({ createNewProject: false, updateProject: false, batchUrls: false })
  const [showSuppliersStatsModal, setShowSuppliersStatsModal] = useState<boolean>(false)
  const [supplersStatsData, setSupplersStatsData] = useState<TODO>(null)
  const [showReEntry, setShowReEntry] = useState<boolean>(false)
  const [showRecontactModal, setShowRecontactModal] = useState<boolean>(false)
  const [showDeletePrompt, setShowDeletePrompt] = useState<DeletePromptGK>({ showDeletePrompt: false, item: { id: "", projectNumber: "" } })
  const [projectIds, setProjectIds] = useState<{ id: string, projectNumber: string }>({ id: "", projectNumber: "" })
  const [reconciliationPREVIEWData, setReconciliationPREVIEWData] = useState<ReconciliationData>()
  const [reconciliationData, setReconciliationData] = useState<ReconciliationGkData>()
  const [suppliersData, setSuppliersData] = useState<GkSuppliersData[]>([])
  const [platformId, setPlatformId] = useState<number | null>(null)
  const [error, setError] = useState({})
  const [checkedAll, setCheckedAll] = useState(false)
  const [showExclution, setShowExclution] = React.useState(false);
  const [formData, setFormData] = useState<GatekeeperNewFormData>(formDataInit)
  const [filterData, setFilterData] = useState<{ createdByNames: string[], countries: string[] }>({ createdByNames: [], countries: [] })
  const [filterItem, setFilterItem] = useState<{ createdByNames: string, countries: string }>({ createdByNames: "", countries: "" })
  const [serachMarketFilter, setSearchMarketFitler] = useState("")
  const [batchUrls, setBatchUrls] = useState<BatchUrlsFormData>({ isLiveFile: "", isTestFile: "" })
  const [batchVariablesResponse, setBatchVariablesResponse] = useState<BatchVariables>({ liveVariables: null, testVariables: null, isBatch: false })
  const [addSuplierData, setAddSuplierData] = useState<GkAddSuppliers>({
    name: "",
    completeUrl: "",
    quotaOutUrl: "",
    screenOutUrl: "",
    qualityFailUrl: "",
  })
  const [modalData, setModalData] = useState<ModalData>({
    respondentIds: [],
    name: ""
  })
  const { markets }: { markets: Market[] } = useSelector((theState: RootState) => (theState.gatekeeperStateReducer));
  const shouldFetchSearchData = useDebounce(() => setDidMount(false), 1000)
  const shouldFetchOriginalData = useDebounce(() => setDidMount(false), 1000)
  const prevSearchValue = usePrevious(searchValue)

  //GK DATA
  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      getProjects(`co/gk/projects/overview?name=${searchValue}&country=${filterItem.countries}&createdByName=${filterItem.createdByNames}`, token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            if (Array.isArray(res.items)) {
              if (res.items.length === 0) {
                setLoadMoreToken("")
              }
              setData(res.items)
              setLoadMoreToken(res.continuationToken)
            }
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })

      // SUPPORTED MARKETS
      getSupportedMarkets("co/gk/projects/supportedmarkets", token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message && !res.detail) {
            dispatch({ type: 'UPDATE_MARKETS', payload: res })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
    }
  }, [token, didMount, dispatch, searchValue, filterItem.countries, filterItem.createdByNames])

  useEffect(() => {
    if (searchValue !== prevSearchValue) {
      if (searchValue !== "") {
        shouldFetchSearchData()
      } else if (didMount) {
        shouldFetchOriginalData()
      }
    }
  }, [searchValue, shouldFetchSearchData, didMount, prevSearchValue, shouldFetchOriginalData])

  //SUPPLIERS
  useEffect(() => {
    setIsLoading(true)
    if (showSupplierModal || supplierSuccessModal) {
      getSuppliers("co/gk/suppliers", token).then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setSuppliersData(res)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
    }

  }, [dispatch, token, showSupplierModal, supplierSuccessModal])

  // FILTERS
  useEffect(() => {
    fetchGetJson("co/gk/projects/filters", token)
      .then((res: TODO) => {
        setFilterData({ createdByNames: [...res.createdByNames], countries: [...res.countries] })
      })
  }, [token])

  const handleSearch = (marketSearch: string) => {
    setSearchMarketFitler(marketSearch)
  }

  const handleFilterItem = (filterName: string, item: string) => {
    if (filterName === "countries" && item === "") {
      setSearchMarketFitler("")
    }

    setFilterItem({ ...filterItem, [filterName]: item })
    setDidMount(false)
  }

  const onChangeBatchVariables = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setBatchUrls({ ...batchUrls, [e.target.name]: e.target.files[0].name })

      const dataFormat = new FormData()
      dataFormat.append("file", e.target.files[0])

      const isTestFile: boolean = e.target.name.includes("Test") ? true : false

      fetchPostFile(`co/gk/projects/uploadcustomvariables?isTestFile=${isTestFile}`, token, dataFormat)
        .then((res: TODO) => {
          if (res.ok === false) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Something went wrong. Please try again.' } })
          } else {
            if (res.hasOwnProperty("fileId")) {
              if (isTestFile) {
                setBatchVariablesResponse({ ...batchVariablesResponse, testVariables: { ...res } })
                setError({ ...error, invalidTestFile: {} })
              } else {
                setBatchVariablesResponse({ ...batchVariablesResponse, liveVariables: { ...res } })
                setError({ ...error, invalidLiveFile: {} })
              }
            } else {
              if (isTestFile) {
                setError({ ...error, invalidTestFile: { message: res } })
              } else {
                setError({ ...error, invalidLiveFile: { message: res } })
              }
            }
          }
        })
    }
  }

  // BATCH URLS 
  const batchUrlsHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleErrorBatchUrls(batchUrls)) {
      setShowCreateNewProject({ ...showCreateNewProject, createNewProject: true, batchUrls: false })
      setBatchUrls({ isLiveFile: "", isTestFile: "" })
      setBatchVariablesResponse({ ...batchVariablesResponse, isBatch: true })
      setError({})
    }
  }

  const onCopyLinkHandler = (url: string) => {
    navigator.clipboard.writeText(url).then(() => {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "URL copied to clipboard" } })
    }, () => {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'URL copied to clipboard' } })
    });
  }

  // Export Poject
  const onExportHandler = async (item: { id: string, projectNumber: string }) => {
    const exportRes: TODO = await fetchGetRes(`co/gk/projects/export/${item.id}/${item.projectNumber}`, token)
    if (exportRes && exportRes.status === 200) {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Project has been queued for export" } })
    } else {
      const exportJson = await exportRes.json()
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: exportJson.error ? exportJson.error : exportJson.message } })
    }
  }

  // Delete Poject
  const onDeleteHandler = async (item: { id: string, projectNumber: string }) => {
    const deleteProject: TODO = await fetchDelete(`co/gk/projects/${item.id}/${item.projectNumber}`, token)
    if (deleteProject && deleteProject.status === 204) {
      setDidMount(false)
      if (data) {
        setShowDeletePrompt({ showDeletePrompt: false, item: { id: "", projectNumber: "" } })
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Project has been deleted" } })
      }
    } else {
      const exportJson = await deleteProject.json()
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: exportJson.error ? exportJson.error : exportJson } })
    }
  }

  // Edit project
  const onEditHandler = (item: { id: string, projectNumber: string }) => {
    fetchGetJson(`co/gk/projects/${item.id}/${item.projectNumber}`, token)
      .then((res: TODO) => {
        for (const [key, value] of Object.entries(res) as TODO) {
          if (formDataInit.hasOwnProperty(key)) {
            if (res.exclusion) {
              setShowExclution(true)
            }
            formDataInit = Object.assign({ ...formDataInit, [key]: value })
          }
        }
        formDataInit.country = { name: markets.find(el => el.countryCode === res.countryCode && el.languageCode === res.languageCode)?.name || res.countryCode, countryCode: res.countryCode, languageCode: res.languageCode }
        setFormData(formDataInit)
        setProjectIds({ projectNumber: item.projectNumber, id: item.id })
        setShowCreateNewProject({ ...showCreateNewProject, updateProject: true })
      })
  }

  const handleCheckedItems = (checked: boolean, id: string) => {
    if (data) {
      const checkedArr = data?.map((item) => (
        item.id === id ?
          {
            ...item, isChecked: checked
          } : { ...item }))
      setData(checkedArr)
    }
  }

  const handleCheckedAll = (checked: boolean) => {
    setCheckedAll(!checkedAll)
    if (data) {
      const checkedArr = data?.map((item) => ({
        ...item, isChecked: checked
      }))
      setData(checkedArr)
    }
  }

  const handleMassExport = () => {
    const massExportArr = data?.filter(item => item.isChecked)
      .map(({ country, createdByName, displayName, createdUtc, gatekeeperTestUrl, gatekeeperUrl, hasBeenReconciled, hasExclusion, isChecked, suppliers, surveyLiveUrl, surveyTestUrl, wave, ...item }) => {
        return item
      })
    fetchPostResOrJson("co/gk/projects/export", token, { gatekeeperProjects: massExportArr },)
      .then(() => {
        const dataCopy = data ? JSON.parse(JSON.stringify(data)) : []
        const checkedArr = dataCopy?.map((item: TODO) => ({
          ...item, isChecked: false
        }))
        setData(checkedArr)
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${massExportArr?.length} projects has been queued for export` } })
      }).catch((err: TODO) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: err.error ? err.error : err.message } })
      })
  }

  const onLoadMoreHandler = () => {
    getProjects(`co/gk/projects/overview?continuationToken=${loadMoreToken}&maxItemCount=30`, token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          if (res.continuationToken) {
            setLoadMoreToken(res.continuationToken)
          } else {
            setLoadMoreToken("")
          }
          if (Array.isArray(res.items)) {
            const dataCopy = data ? JSON.parse(JSON.stringify(data)) : []
            // checked all when load more
            if (checkedAll) {
              const checkedArr = res.items?.map((item: TODO) => ({
                ...item, isChecked: true
              }))
              setData([...dataCopy, ...checkedArr])
            } else {
              setData([...dataCopy, ...res.items])
            }
          }
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }

  const handleErrorAddNewSupplier = (obj: GkAddSuppliers) => {
    let emptyFieldError = {}

    for (const [key, value] of Object.entries(obj)) {
      if (value === "") {
        emptyFieldError = Object.assign({ ...emptyFieldError, [key]: { [key]: true, invalidUrl: false } })
      }
      if (value !== "" && key !== "name") {
        const validURL = isValidUrl(value)
        !validURL && (emptyFieldError = Object.assign({ ...emptyFieldError, [key]: { [key]: false, invalidUrl: true } }))
      }
    }

    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const handleErrorBatchUrls = (obj: BatchUrlsFormData) => {
    let emptyFieldError = {}

    if (obj.isLiveFile === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, isLiveFile: true })
    } else {
      if (batchVariablesResponse.liveVariables?.fileId === "") {
        emptyFieldError = Object.assign({ ...emptyFieldError, isLiveFile: true })
      }
    }

    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const onHide = (type: string) => {
    if (type === "Suppliers") {
      setShowSupplierModal(false)
    } else if (type === "Suppliers New") {
      setShowNewSupplierModal(false)
    } else if (type === "Suppliers Success") {
      setSupplierSuccessModal(false)
    } else if (type === "Reconciliation") {
      setShowCreateReconciliationModal(false)
    } else if (type === "Reconciliation Overview") {
      setShowOverviewReconciliationModal(false)
    } else if (type === "Reconciliation Preview") {
      setShowPreviewReconciliationModal(false)
    } else if (type === "New Project") {
      setShowCreateNewProject({ updateProject: false, createNewProject: false, batchUrls: false })
      setFormData({
        displayName: '',
        country: { name: "", countryCode: "", languageCode: "" },
        loi: "",
        surveyTestUrl: '',
        surveyLiveUrl: '',
        useResearchDefender: true,
        wave: "",
        exclusion: null,
        countryCode: "",
        allowReentry: false,
        recontactIds: null,
        redirectCaptureParameter: ""
      })
      setShowExclution(false)
      setError({})
      setBatchVariablesResponse({ liveVariables: null, testVariables: null, isBatch: false })
    } else if (type === "Batch URL's") {
      setShowCreateNewProject({ ...showCreateNewProject, batchUrls: false })
      setBatchUrls({ isLiveFile: "", isTestFile: "" })
      setBatchVariablesResponse({ liveVariables: null, testVariables: null, isBatch: false })
      setError({})
    }
    else if (type === "Re-Entry") {
      setShowReEntry(false)
    }
    else if (type === "DeletePrompt") {
      setShowDeletePrompt({ ...showDeletePrompt, showDeletePrompt: false })
    }
    else if (type === "Recontact") {
      setShowRecontactModal(false)
    }
    else if (type === "ProjectStats") {
      setShowSuppliersStatsModal(false)
    }
  }

  const addNewSupplier = () => {
    setAddSuplierData({
      name: "",
      completeUrl: "",
      quotaOutUrl: "",
      screenOutUrl: "",
      qualityFailUrl: "",
    })
    setShowSupplierModal(false)
    setShowNewSupplierModal(true)
  }

  const saveSupplier = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleErrorAddNewSupplier(addSuplierData)) {
      fetchPostResOrJson("co/gk/suppliers", token, addSuplierData)
        .then(async (res: TODO) => {
          if (res.status === 200 && !res.error && !res.message) {
            const { platformId } = await res.json()
            setPlatformId(platformId)
            setShowNewSupplierModal(false)
            setSupplierSuccessModal(true)
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.detail } })
          }
        })
    }
  }

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const returnItemNew = () => {
    return (
      <div className="d-flex flex-column">
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowCreateNewProject({ ...showCreateNewProject, createNewProject: true })}>
          New project
        </span>
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowCreateReconciliationModal(true)}>
          New reconciliation
        </span>
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowCreateNewProject({ ...showCreateNewProject, batchUrls: true })}>
          Batch URLs
        </span>
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowRecontactModal(true)}>
          Recontact
        </span>
      </div>
    );
  };

  const returnItemMore = () => {
    return (
      <div className="d-flex flex-column">
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowOverviewReconciliationModal(true)}>
          Reconciliation overview
        </span>
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowSupplierModal(true)}>
          Suppliers
        </span>
        <span className="d-flex align-items-center dropdown-item px-4 cursor-pointer" onClick={() => setShowSearchModal(true)}>
          Search Walr ID
        </span>
      </div>
    );
  };

  const handleErrorNewObject = (obj: ModalData) => {
    let emptyFieldError = {}
    if (obj.respondentIds.length < 1) {
      emptyFieldError = Object.assign({ ...emptyFieldError, respondentIds: true })
    }
    if (obj.name === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, name: true })
    }
    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const handlePreview = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleErrorNewObject(modalData)) {
      setIsLoading(true)
      fetchPostResOrJson("au/r/preview", token, modalData)
        .then(async (res: TODO) => {
          setIsLoading(false)
          try {
            const body = await res.json()
            setReconciliationPREVIEWData(body)

          } catch (err) {
            setReconciliationPREVIEWData(res)
          }
        })
      setShowCreateReconciliationModal(false);
      setModalData({
        respondentIds: [],
        name: ""
      })
      setShowPreviewReconciliationModal(true)
    }
  };

  const handleNewReconcile = () => {
    setError({})
    setShowPreviewReconciliationModal(false)
    setShowCreateReconciliationModal(true)
  }

  const handleShowSuppliers = (item: TODO | null) => {
    setShowSuppliersStatsModal(true)
    setSupplersStatsData(item)

  }

  const exportAllDataFromProject = ({ id, displayName }: { id: string, displayName: string }) => {
    const projectId = id;
    if (projectId) {
      fetchGetBlob(`co/gk/respondents/gatekeeperproject/${projectId}`, token)
        .then((res: TODO) => {
          if (!res.error && !res.message && typeof res !== "string") {
            fileManagerDownloadHandler(res, { displayName: `${displayName ? displayName : projectId}.csv` })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The project's transactions have been exported successfully` } })
          } else {
            if (typeof res === "string") {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res } })
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          }
        })
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Project ID is missing" } })
    }
  }

  return (
    <section className='main gk-container p-0'>
      <div className='d-flex flex-column w-100 projects'>
        <div className="d-flex justify-content-center flex-column w-100 px-6 pt-5 pb-2">
          <h1 className="stronger h4">Gatekeeper</h1>
        </div>
        {/* Search container */}
        <div className="d-flex justify-content-between w-100 px-6 py-0">
          <div className='input-group flex-grow-1 gk-action-toolbar' >
            <div className='d-flex divider mr-3'>
              <DropdownButton
                text="Create new"
                menuPosition="left"
                icon="add"
                className="btn-primary icon-b mr-2"
                items={[{ text: "Create new" }]}
                renderItem={returnItemNew}
                fillColor='white'
              />
              <DropdownButton
                text="More"
                menuPosition="left"
                className="btn-shadow icon-r mr-3"
                items={[{ text: "More" }]}
                renderItem={returnItemMore}
                fillColor='primary'
              />
            </div>
            {/*Filters */}
            {filterItem.createdByNames ?

              <button type="button" onClick={() => handleFilterItem("createdByNames", "")} className="btn btn-shadow btn-icon icon-r mr-2">
                {filterItem.createdByNames}
                <Icon type="minor-close" className='ml-1' />
              </button> :
              <div className="dropdown details-dropdown">
                <button type="button" className="btn btn-shadow btn-icon icon-l mr-2" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Icon type="minor-user" className='mr-1' />
                  Created by
                </button>
                <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
                  <div className='d-flex flex-column market'>
                    {filterData.createdByNames.map((item, index) => (
                      <div key={index} className='d-flex justify-content-center align-items-center' >
                        {filterItem.createdByNames === item && <Icon type='checkmark' />}
                        <button key={index} className="dropdown-item text-truncate" type="button" onClick={() => handleFilterItem("createdByNames", item)} >{item}</button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
            {filterItem.countries ?
              <button type='button' onClick={() => handleFilterItem("countries", "")} className="btn btn-shadow btn-icon icon-r">
                {filterItem.countries}
                <Icon type="minor-close" className='ml-1' />
              </button> :
              <div className="dropdown details-dropdown">
                <button type='button' className="btn btn-shadow btn-icon icon-l" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Icon type="minor-globe" className='mr-1' />
                  Country
                </button>
                <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
                  <div className='d-flex px-3 pb-3 mt-1'><input className='form-control' type="text" placeholder="Search" id="myInput" onChange={(e) => handleSearch(e.target.value)} /></div>
                  <div className='d-flex flex-column market'>
                    {filterData.countries
                      .filter(market => market?.toLocaleLowerCase().includes(serachMarketFilter))
                      .map((item, index) => (
                        <div key={index} className='d-flex justify-content-center align-items-center' >
                          {filterItem.countries === item && <Icon type='checkmark' />}
                          <button key={index} className="dropdown-item text-truncate" type="button" onClick={() => handleFilterItem("countries", item)} >{item}</button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            }
          </div>
          <input
            name='Search'
            type="text"
            value={searchValue}
            className="form-control search-input bg-white flex-shrink-0"
            placeholder="Search"
            aria-label="Search"
            onChange={(e: TODO) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="d-flex px-6 py-5 w-100">
          <div className="nav-board d-flex justify-content-center flex-column overflow-hidden w-100 bg-white">
            <div className="accordion w-100 p-3" id="accordionExample">
              <div className="accordion-item">
                <table className="table table-responsive-lg quality-table gk-table m-0">
                  <thead>
                    <tr>
                      <th className='text-left'>
                        <div className='d-flex align-items-center'>
                          <input
                            id="checkAll"
                            type="checkbox"
                            className="k-checkbox mr-3"
                            checked={checkedAll}
                            onChange={(e) => handleCheckedAll(e.currentTarget.checked)}
                          />

                          <label className="form-check-label medium strong" htmlFor="checkAll">
                            Project name
                          </label>
                        </div>
                      </th>
                      <th className='text-left medium strong'>Created by</th>
                      <th className='text-left medium strong'>Date</th>
                      <th className='text-left medium strong'>Country</th>
                      <th className='text-center medium strong'>Wave</th>
                      <th className='text-center medium strong'>Reconciled</th>
                      <th className='text-center medium strong'>Exclusions</th>
                      <th className='text-center medium strong text-nowrap'>Client test</th>
                      <th className='text-center medium strong text-nowrap'>Client live</th>
                      <th className='text-center medium strong text-nowrap'>Sample test</th>
                      <th className='text-center medium strong text-nowrap'>Sample live</th>
                      <th className='text-center' />
                    </tr>
                  </thead>
                  {
                    data !== null ?
                      data?.length > 0 ?
                        data.map((item, id) => (
                          <React.Fragment key={id}>
                            <tbody className={`border-0 ${item.isChecked && 'checked'}`} >
                              <tr key={id}>
                                <td className='text-left' >
                                  <div className='d-flex align-items-center'>
                                    <input
                                      role='button'
                                      id={item.id}
                                      type="checkbox"
                                      className="k-checkbox mr-3"
                                      checked={item.isChecked ? item.isChecked : false}
                                      onChange={(e) => handleCheckedItems(e.currentTarget.checked, item.id)}
                                    />

                                    <label className="form-check-label m-0 strong" htmlFor={item.id}>
                                      {item.displayName !== null ? item.displayName : item.projectNumber}
                                    </label>
                                  </div>
                                </td>
                                <td className='text-left'>{item.createdByName}</td>
                                <td className='text-left text-nowrap'>{formatDate(item.createdUtc, "DATE_MED")}</td>
                                <td className='text-left'>
                                  <span className="badge small" >{item.country?.split("-")[0]}</span></td>
                                <td>{item.wave}</td>
                                <td>
                                  {item.hasBeenReconciled ? <Icon type='checkmark' /> : <span className='text-empty'>{`-`}</span>}
                                </td>
                                <td>
                                  {item.hasExclusion ? <Icon type='checkmark' /> : <span className='text-empty'>{`-`}</span>}
                                </td>
                                <td>
                                  <div className='d-flex align-items-center justify-content-center url'>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' title={item.surveyTestUrl} onClick={() => onCopyLinkHandler(item.surveyTestUrl)} className='btn btn-transparent p-05'>< Icon type='copy' className="pe-none" /></button>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center justify-content-center url'>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' title={item.surveyLiveUrl} onClick={() => onCopyLinkHandler(item.surveyLiveUrl)} className='btn btn-transparent p-05'>< Icon type='copy' className="pe-none" /></button>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center justify-content-center url'>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' title={item.gatekeeperTestUrl.replace('&recid=[%RSFN%]', '')} onClick={() => onCopyLinkHandler(item.gatekeeperTestUrl.replace('&recid=[%RSFN%]', ''))} className='btn btn-transparent p-05'>< Icon type='copy' className="pe-none" /></button>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center justify-content-center url'>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' title={item.gatekeeperUrl.replace('&recid=[%RSFN%]', '')} onClick={() => onCopyLinkHandler(item.gatekeeperUrl.replace('&recid=[%RSFN%]', ''))} className='btn btn-transparent p-05'>< Icon type='copy' className="pe-none" /></button>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex gk-row-toolbar justify-content-end align-items-center'>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' onClick={() => exportAllDataFromProject(item)} className='btn p-05 mr-1 btn-transparent' title="Export all transactions">
                                        <i style={{ padding: '0.5rem 0.25rem' }} className="far fa-cloud-download pe-none fa-lg" />
                                      </button>
                                    </Tooltip>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' onClick={() => onExportHandler(item)} className='btn p-05 mr-1 btn-transparent' title="Export">
                                        <Icon type='export' className="pe-none" />
                                      </button>
                                    </Tooltip>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' onClick={() => handleShowSuppliers(item)} className='btn p-05 mr-1 btn-transparent' title="Statistics">
                                        <Icon type='gk-stats' className="pe-none" />
                                      </button>
                                    </Tooltip>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' onClick={() => onEditHandler(item)} className='btn p-05 mr-1 btn-transparent' title="Edit">
                                        <Icon type='dashboard-edit' className="pe-none" />
                                      </button>
                                    </Tooltip>
                                    <Tooltip anchorElement="target" position="top">
                                      <button type='button' onClick={() => setShowDeletePrompt({ showDeletePrompt: true, item })} className='btn p-05 btn-transparent' title="Delete">
                                        <Icon type='delete-alt' className="pe-none" />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </React.Fragment>
                        ))
                        :
                        <tbody>
                          <tr>
                            <td colSpan={12} className="py-5">
                              <h5 className='my-5 text-muted'>No projects have been found</h5>
                            </td>
                          </tr>
                        </tbody>
                      :
                      <tbody>
                        <tr>
                          <td colSpan={12} className="py-5">
                            <FullHeightSpinner />
                          </td>
                        </tr>
                      </tbody>
                  }
                </table>
              </div>
            </div>
            {
              loadMoreToken &&
              <div className="d-flex justify-content-start w-100 border-top p-4" >
                <button type='button' onClick={onLoadMoreHandler} className="btn btn-icon icon-l btn-shadow"><Icon type="refresh" className='mr-05' /> Load more projects</button>
              </div>
            }
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          {data && data?.filter(item => item.isChecked && item.isChecked).length > 0 &&
            <MassActionPanel
              numberOfSelectedProjects={data?.filter(item => item.isChecked && item.isChecked).length}
              handleMassExport={handleMassExport}
            />}
        </div>
        {showSupplierModal &&
          <GateKeeperSupplierModal
            isLoading={isLoading}
            suppliersData={suppliersData}
            onHide={onHide}
            addNewSupplier={addNewSupplier}
          />
        }

        {showNewSupplierModal &&
          <GateKeeperNewSupplierModal
            addSuplierData={addSuplierData}
            setAddSuplierData={setAddSuplierData}
            onHide={onHide}
            saveSupplier={saveSupplier}
            error={error}
          />
        }
        {supplierSuccessModal &&
          <SaveSupplierSuccessModal
            onHide={onHide}
            platformId={platformId}
          />}
        {showCreateReconciliationModal &&
          <GKNewReconciliationModal
            onHide={onHide}
            modalData={modalData}
            setModalData={setModalData}
            handlePreview={handlePreview}
            error={error}
          />}
        {showPreviewReconciliationModal &&
          <GKPreviewReconciliationModal
            reconciliationData={reconciliationPREVIEWData}
            setReconciliationData={setReconciliationPREVIEWData}
            isLoading={isLoading}
            handleNewReconcile={handleNewReconcile}
            onHide={onHide}
            token={token}
          />}
        {showOverviewReconciliationModal &&
          <GKOverviewReconciliationModal
            reconciliationData={reconciliationData}
            setReconciliationData={setReconciliationData}
            onHide={onHide}
          />}
        {(showCreateNewProject.createNewProject || showCreateNewProject.updateProject) &&
          <GKCreateNewProject
            setDidMount={setDidMount}
            gkProjectsToExclude={gkProjectsToExclude}
            setShowExclution={setShowExclution}
            showCreateNewProject={showCreateNewProject}
            setShowCreateNewProject={setShowCreateNewProject}
            setGKProjectsToExclude={setGKProjectsToExclude}
            showExclution={showExclution}
            formData={formData}
            setFormData={setFormData}
            onHide={onHide}
            projectIds={projectIds}
            batchVariables={batchVariablesResponse}
            setBatchVariablesResponse={setBatchVariablesResponse}
            showReEntry={showReEntry}
            setShowReEntry={setShowReEntry} />}

        {showCreateNewProject.batchUrls &&
          <BatchURLsModal
            batchUrls={batchUrls}
            batchUrlsHandler={batchUrlsHandler}
            onHide={onHide}
            error={error}
            onChangeBatchVariables={onChangeBatchVariables}
            batchVariables={batchVariablesResponse}
          />
        }
        {showDeletePrompt.showDeletePrompt &&
          <DeleteProptModal
            onHide={onHide}
            item={showDeletePrompt.item}
            handleDelete={onDeleteHandler}
          />
        }
        {showSuppliersStatsModal &&
          <SuppliersStatsModal
            onHide={onHide}
            data={supplersStatsData}
          />
        }
      </div>
      {showRecontactModal &&
        <RecontactModal
          onHide={onHide}
          formData={formData}
          setFormData={setFormData}
          setShowCreateNewProject={setShowCreateNewProject}
          showCreateNewProject={showCreateNewProject}
        />}
      {
        showSearchModal &&
        <WidSearchModal onHide={setShowSearchModal} />
      }
    </section >
  )
}