import { Fragment, useState } from "react"

import { DropdownButton } from "../../shared/DropdownButton/DropdownButton";
import { GKstatisticsModal } from "../../Audiences/Statistics/GKstatisticsModal";
import { useHistory, useParams } from "react-router-dom";
import type { AudienceActionsType } from "../../Audiences/AudienceActionsButton/returnActionItems";
import { DeleteModal } from "./DeleteModal";
import type { Batch } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import type { RootState } from "../../../store/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import type { BatchOverviewType } from "../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";
import { fetchGetJsonData, fetchGetRes } from "../../../services/services";
import { setBatch } from "../../../store/reducers/batchReducer/batchReducer";

type Props = {
  batch: Batch;
  batches: Batch[];
  setBatches: (batches: Batch[]) => void;
  setIsOverlayLoading: (loading: boolean) => void;
}

export const AudienceBatchesActions = ({ batch, batches, setBatches, setIsOverlayLoading }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { displayName, sampleInfo } = useSelector((state: RootState) => state.audienceDataReducer);
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;

  const history = useHistory();
  const dispatch = useDispatch()

  const [showGKStatsModal, setShowGKStatsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const actionItems = [
    {
      name: "Edit",
      icon: "edit",
      id: "edit"
    },
    {
      name: "Copy",
      icon: "copy",
      id: "copy"
    },
    {
      name: "Export",
      icon: "export",
      id: "export"
    },
    {
      name: "Stats",
      icon: "gk-stats",
      id: "stats"
    },
    {
      name: "Delete",
      icon: "delete",
      id: "delete"
    }
  ];

  const onCopyHandler = () => {
    setIsOverlayLoading(true);
    fetchGetJsonData<BatchOverviewType>(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batch?.id}/copy`, token)
      .then((res) => {
        const batchCopyRes = res as BatchOverviewType;
        dispatch(setBatch({ ...batchCopyRes, batchAction: "copy" }));
        history.push({ pathname: "/projects/audience/batch/copy" });
        setIsOverlayLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        setIsOverlayLoading(false);
      })
  }

  const onExportHandler = async () => {
    const exportRes: TODO = await fetchGetRes(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batch?.id}/export`, token)
    if (exportRes && exportRes.status === 200) {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Batch has been queued for export" } })
    } else {
      const exportJson = await exportRes.json()
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: exportJson.error ? exportJson.error : exportJson.message } })
    }
  }

  const actionItemsClick = (actionType: AudienceActionsType) => {
    switch (actionType) {
      case "edit":
        history.push({ pathname: `/projects/${projectId}/samples/${sampleId}/audience/${audienceId}/batch/${batch.id}` })
        break;
      case "stats":
        setShowGKStatsModal(true);
        break;
      case "copy":
        onCopyHandler();
        break;
      case "export":
        onExportHandler();
        break;
      case "delete":
        setShowDeleteModal(true);
        break;
    }
  };

  return (
    <Fragment>
      {
        showGKStatsModal &&
        <GKstatisticsModal
          onHide={setShowGKStatsModal}
          type={"batch"}
          sample={{ id: sampleId, name: sampleInfo?.displayName ? sampleInfo?.displayName : "", projectId: projectId }}
          audience={{ id: audienceId, name: displayName }}
          batch={{ id: batch.id, name: batch.displayName }}
        />
      }

      {
        showDeleteModal &&
        <DeleteModal batch={batch} batches={batches} setBatches={setBatches} setShowDeleteModal={setShowDeleteModal} />
      }
      <DropdownButton
        items={actionItems}
        className="btn btn-transparent"
        textField="name"
        hideChevron={true}
        onItemClick={(e) => {
          actionItemsClick(e.item.id)
        }}
      />
    </Fragment>
  )
}