import { Icon } from "../../shared/Icon/Icon"
import { Skeleton } from "../../shared/Skeleton/Skeleton"

export const AuditLogSkeleton = () => {
  return (
    <div className='board-content-container' >
      <table className="table border m-0" style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th className="border-0">Name</th>
            <th className="border-0">Event type</th>
            <th className="border-0">Date</th>
            <th className="border-0" style={{ width: "50px" }} />
          </tr>
        </thead>

        <tbody>
          {[...Array(15).keys()].map((item) => (
            <tr key={item} className="audience-audit-log-row">
              <td className='text-left text-primary'>
                <Skeleton className="h-24" />
              </td>
              <td className='text-left text-primary text-capitalize'>
                <Skeleton className="h-24" />
              </td>
              <td className='text-left text-primary'>
                <Skeleton className="h-24" />
              </td>
              <td className="text-right">
                <Icon type="chevron" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}