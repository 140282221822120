import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { returnActionItems, type AudienceActionsType } from "./returnActionItems";
import type { AudiencesType, Batch, Sample } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import type { RootState } from "../../../store/reducers/rootReducer";
import fileManagerDownloadHandler from "../../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler";
import { DropdownButton } from "../../shared/DropdownButton/DropdownButton";
import { GKstatisticsModal } from "../Statistics/GKstatisticsModal";
import { ReconcileModal } from "../../AudienceOverview/shared/ReconcileModal";
import { SampleModal } from "../Modals/SampleModal";
import { DeleteModal } from "../Modals/DeleteModal";
import { createNewAudience, setAudience } from "../../../store/reducers/audienceAccessReducer/audienceAccessReducer";
import { fetchGetBlob, fetchGetJsonData, fetchGetRes } from "../../../services/services";
import type { AudienceCopyType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes";
import type { BatchOverviewType } from "../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";
import { createNewBatch, setBatch } from "../../../store/reducers/batchReducer/batchReducer";
import { editAudiencesNavigation } from "../../../store/reducers/audiencesNavigationReducer/audiencesNavigationReducer";

type Props = {
  sample: Sample;
  audience?: AudiencesType;
  batch?: Batch;
  type: "sample" | "audience" | "batch";
  setIsOverlayLoading: (show: boolean) => void;
}

export const AudienceActionsDropdown = ({ sample, audience, batch, type, setIsOverlayLoading }: Props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const history = useHistory();

  const [showGKStatsModal, setShowGKStatsModal] = useState(false);
  const [showNewReconcileModal, setShowNewReconcileModal] = useState(false);
  const [showCreateSampleModal, setShowCreateSampleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const actionItems = returnActionItems(type);

  const exportAllTransactions = (sample: Sample, type: "sample" | "audience" | "batch", audience?: AudiencesType) => {
    const fetchUrl = type === "sample" ? `au/a/respondents/samples/${sample.id}` : `au/a/respondents/audiences/${audience?.id}`
    fetchGetBlob(fetchUrl, token)
      .then((res: TODO) => {
        if (!res.error && !res.message && typeof res !== "string") {
          fileManagerDownloadHandler(res, { displayName: `${type === "sample" ? sample.name : audience?.displayName}.csv` })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The project's transactions have been exported successfully` } })
        } else {
          if (typeof res === "string") {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        }
      })
  }

  const onExportHandler = async (sample: Sample, type: "sample" | "audience" | "batch", audience?: AudiencesType, batch?: Batch) => {
    let fetchUrl = "";
    switch (type) {
      case "sample":
        fetchUrl = `au/a/projects/${sample.projectId}/samples/${sample.id}/export`;
        break;
      case "audience":
        fetchUrl = `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/export`;
        break;
      case "batch":
        fetchUrl = `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/batches/${batch?.id}/export`;
        break;
    }

    const exportRes: TODO = await fetchGetRes(fetchUrl, token)
    if (exportRes && exportRes.status === 200) {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${type.charAt(0).toUpperCase() + type.slice(1)} has been queued for export` } })
    } else {
      const exportJson = await exportRes.json()
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: exportJson.error ? exportJson.error : exportJson.message } })
    }
  }

  const onCopyHandler = (sample: Sample, audience?: AudiencesType, batch?: Batch) => {
    const copyUrl = type === "audience" ?
      `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/copy`
      :
      `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/batches/${batch?.id}/copy`;

    dispatch(editAudiencesNavigation({ projectId: sample.projectId, sampleId: sample.id, audienceId: audience?.id }))

    fetchGetJsonData<AudienceCopyType | BatchOverviewType>(copyUrl, token)
      .then((res) => {
        if (type === "audience") {
          const audienceCopyRes = res as AudienceCopyType;
          dispatch(setAudience({ ...audienceCopyRes, audienceAction: "copy" }));
          dispatch(setBatch({ ...audienceCopyRes.batchResponse, batchAction: "copy" }));
          history.push({ pathname: "/projects/audience/copy" });
        }

        if (type === "batch") {
          const batchCopyRes = res as BatchOverviewType;
          dispatch(setBatch({ ...batchCopyRes, batchAction: "copy" }));
          history.push({ pathname: "/projects/audience/batch/copy" });
        }
        setIsOverlayLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        setIsOverlayLoading(false);
      })
  }

  const actionItemsClick = (actionType: AudienceActionsType, sample: Sample, audience?: AudiencesType, batch?: Batch) => {
    switch (actionType) {
      case "edit":
        if (type === "sample") {
          setShowCreateSampleModal(true);
          break;
        }

        if (type === "audience") {
          history.push({ pathname: `/projects/${sample?.projectId}/samples/${sample.id}/audience/${audience?.id}` })
        }

        if (type === "batch") {
          history.push({ pathname: `/projects/${sample?.projectId}/samples/${sample.id}/audience/${audience?.id}/batch/${batch?.id}` })
        }
        break;
      case "stats":
        setShowGKStatsModal(true);
        break;
      case "copy":
        setIsOverlayLoading(true)
        onCopyHandler(sample, audience, batch)
        break;
      case "export":
        onExportHandler(sample, type, audience, batch)
        break;
      case "exportAllTransactions":
        exportAllTransactions(sample, type, audience);
        break;
      case "reconcile":
        setShowNewReconcileModal(true);
        break;
      case "addAudience":
        dispatch(createNewAudience({ projectId: sample.projectId, sampleId: sample.id, audienceAction: "new" }));
        dispatch(createNewBatch({ batchAction: "new" }));
        history.push({ pathname: "/projects/audience/create" })
        break;
      case "delete":
        setShowDeleteModal(true);
        break;
    }
  };
  return (
    <Fragment>
      {
        showGKStatsModal &&
        <GKstatisticsModal
          onHide={setShowGKStatsModal}
          type={type}
          sample={{ id: sample.id, name: sample.name, projectId: sample.projectId }}
          audience={{ id: audience?.id, name: audience?.displayName }}
          batch={{ id: batch?.id, name: batch?.id }}
        />
      }

      {
        showNewReconcileModal && <ReconcileModal setShowNewReconcileModal={setShowNewReconcileModal} type={"audiences"} projectId={sample.projectId} />
      }

      {
        showCreateSampleModal &&
        <SampleModal setIsOverlayLoading={setIsOverlayLoading} setShowCreateSampleModal={setShowCreateSampleModal} type="edit" sample={sample} />
      }

      {
        showDeleteModal &&
        <DeleteModal type={type} setShowDeleteModal={setShowDeleteModal} sample={sample} audience={audience} batch={batch} />
      }

      <DropdownButton
        items={actionItems}
        className="btn btn-transparent"
        textField="name"
        hideChevron={true}
        onItemClick={(e) => {
          actionItemsClick(e.item.id, sample, audience, batch)
        }}
      />
    </Fragment>
  )
}