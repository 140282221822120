export default (res, selected) => {
  const reader = new FileReader();
  const blob = new Blob([res]);
  reader.readAsDataURL(blob);
  reader.onloadend = (e) => {
    const link = document.createElement("a");
    link.href = reader.result;
    link.download = selected.displayName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}