// @ts-nocheck
import type { BatchOverviewType, Quotas, QuotaTargets } from '../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes';
import { Icon } from '../../../shared/Icon/Icon';

interface Props {
  quotas?: Quotas[] | null;
  audience: BatchOverviewType
}

export const TagretingQuota = ({ quotas, audience }: Props) => {
  return (
    <article className='nav-board d-flex flex-column bg-white answer-layout p-5'>
      <p className="innerboard-header d-flex align-items-center stronger mb-4 h-32">Quotas</p>
      <div className='board-content-container' >
        <table className="table target-table mb-0 border rounded-0">
          <tbody>
            <tr>
              <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota name</th>
              <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes</th>
              <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota target</th>
              <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes remaining </th>
              <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Entrants</th>
              <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Conversion</th>
            </tr>
            <tr >
              <th className='align-middle' scope="row">
                Total
              </th>
              <td className='text-left align-middle py-0' width={"15%"}>{audience.completes || 0}</td>
              <td className='text-left py-0 align-middle' width={"15%"} >
                <input type="text" className="form-control w-50" disabled id="inlineFormInputGroup" defaultValue={audience.quantity} />
              </td>
              <td className='text-left align-middle py-0' width={"15%"}>{audience.quantity ? audience.quantity - audience.completes : 0}</td>
              <td className='text-left align-middle py-0' width={"15%"}>{audience.entrants || 0}</td>
              <td className='text-left align-middle py-0' width={"15%"}>{audience.actualIncidenceRate ? `${audience.actualIncidenceRate}%` : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {quotas?.map((quota) =>
      (
        <div key={quota.questionId} className="accordion h-100 mt-3" id="accordionExample">
          <div className="accordion-item table-shadow">
            <div className='board-content-container' >
              <table className="table target-table mb-0 border-0 rounded-0">
                <thead className="accordion-header" id={`heading${quota.questionId}`} data-toggle="collapse" data-target={`#main${quota.questionId}`} aria-expanded="false" aria-controls="main">
                  <tr className='position-relative'>
                    <th scope="row" className='cursor-pointer p-4 align-middle'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex flex-column'>
                          <p className='text-indigo-dark strong p-0 mb-1' >{quota.name}</p>
                          <h6 className='text-primary large strong p-0 m-0' >{quota.text}</h6>
                        </div>
                        <Icon type="chevron-mini-down-alt" className="position-absolute mt-1 accordion-icon" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody
                  id={`main${quota.questionId}`}
                  className="accordion-collapse collapse mt-1"
                  aria-labelledby={`heading${quota.questionId}`}
                  data-parent="#accordionExample">
                  <tr>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota name</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota target</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes remaining </th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Entrants</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Conversion</th>
                  </tr>
                  {
                    quota.targets.map((item: QuotaTargets) => (
                      <tr key={item.id} >
                        <th scope="row">
                          <div className='d-flex flex-row flex-wrap align-items-center h-100'>
                            {item.answers.map((answer) => (
                              <p key={answer.id} className='text-nowrap text-truncate small strong badge px-2 py-1 m-0' >{answer.text ? answer.text : `${answer.start} to ${answer.end}`}</p>
                            ))}
                          </div></th>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.completes || 0}</td>
                        <td className='text-left py-0 align-middle' width={"15%"} >
                          <input type="text" className="form-control w-50" disabled id="inlineFormInputGroup" defaultValue={item.targetCompletes} />
                        </td>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.completesRemaining}</td>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.entrants || 0}</td>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.incidenceRate ? `${item.incidenceRate}%` : 0}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </article>
  )
}