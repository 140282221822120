import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { AudienceDetails } from './Details/AudienceDetails'
import { AudienceReconcile } from './Reconcile/AudienceReconcile'
import type { AudienceOverviewTabsType, AudienceOverviewType } from '../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage'
import { setAudience } from '../../store/reducers/audienceAccessReducer/audienceAccessReducer';
import type { RootState } from '../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { SupplierUrlBuilder } from './SupplierUrlBuilder/SupplierUrlBuilder';
import { AudienceHeader } from './AudienceHeader/AudienceHeader';
import { AudienceAuditLog } from './AuditLog/AudienceAuditLog';
import { Batches } from './Batches/Batches';
import { fetchGetJsonData } from '../../services/services';

export const AudienceOverview = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [selectedTab, setSelectedTab] = useState<AudienceOverviewTabsType>("Details")
  const [didMount, setDidMount] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, message: '' });
  const [isLoading, setIsLoading] = useState(true);

  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      if (projectId && sampleId && audienceId) {
        fetchGetJsonData<AudienceOverviewType>(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}`, token)
          .then((res) => {
            dispatch(setAudience({ ...res, audienceAction: "edit" }));
            setIsLoading(false);
          }).catch((err) => {
            setErrorModal({ show: true, message: err?.message });
            setIsLoading(false);
          })
      } else {
        setIsLoading(false);
      }
    }
  }, [projectId, sampleId, audienceId, didMount, dispatch, token])

  const SelectedTabComponents = () => {
    switch (selectedTab) {
      case "Details":
        return <AudienceDetails />
      case "Batches":
        return <Batches />
      case "Reconcile":
        return <AudienceReconcile />
      case "Audit log":
        return <AudienceAuditLog type="audience" />
      case "Links":
        return <SupplierUrlBuilder />
      default: null;
    }
  }

  return (
    <section className='main audiences-container overview h-100'>
      {isLoading ?
        <FullHeightSpinner /> :
        <Fragment>
          <AudienceHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          <section className='overview-container d-flex flex-column p-6 gap-lg'>
            <SelectedTabComponents />
          </section>
        </Fragment>
      }

      {
        errorModal.show &&
        <ErrorMessage
          type="modal"
          errorMessage={errorModal.message}
          onHide={() => setErrorModal({ show: false, message: '' })}
        />
      }
    </section >
  )
}