import { Fragment } from "react";

import { formatDate } from "../../shared/DateUtils/DateUtils";
import { Icon } from "../../shared/Icon/Icon";
import type { AuditLogType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes";

type Props = {
  filteredAuditLogData?: AuditLogType[];
  expandAllRows: boolean;
}
export const AuditLogTable = ({ filteredAuditLogData, expandAllRows }: Props) => {

  const formatText = (path: string, value: string) => {
    if (path === "LastModifiedUtc") {
      return formatDate(value, "DATETIME_MED")
    }

    if (path === "LaunchDate") {
      return formatDate(value, "DATE_MED")
    }
    return value
  }

  return (
    <Fragment>
      {filteredAuditLogData?.length === 0 ?
        <div className="d-flex flex-column align-items-center justify-content-center gap-md my-3" style={{ height: "65svh" }}>
          <i className="fal fa-folder-open fa-2x text-muted" />
          <p className="text-muted m-0">
            No results
          </p>
        </div>
        :
        <table className="table border m-0" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th className="border-0">Name</th>
              <th className="border-0">Event type</th>
              <th className="border-0">Date</th>
              <th className="border-0" style={{ width: "50px" }} />
            </tr>
          </thead>

          <tbody>
            {filteredAuditLogData?.map((item, index) => (
              <Fragment key={item.id}>
                <tr data-toggle="collapse" data-target={`#data-target-${index}`} aria-expanded={expandAllRows} className="audience-audit-log-row">
                  <td className='text-left text-primary'>{item.name}</td>
                  <td className='text-left text-primary text-capitalize'>{item.item.action}</td>
                  <td className='text-left text-primary'>{formatDate(item.date, "DATETIME_MED")}</td>
                  <td className="text-right">{item.diffs ? <Icon type="chevron" className="audience-audit-log-row-icon" /> : ""}</td>
                </tr>

                <tr>
                  <td colSpan={4} className="p-0 text-left border-0">
                    {item.diffs && (
                      <div id={`data-target-${index}`} className={`collapse ${expandAllRows ? "show" : ""}`}>
                        <table className="table m-0 target-table pb-1" style={{ tableLayout: "fixed", backgroundColor: "#e9ecef66" }}>
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Previous</th>
                              <th>New</th>
                              <th style={{ width: "50px" }} />
                            </tr>
                          </thead>
                          <tbody>
                            {item?.diffs?.map((diff, index) =>
                              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                              <tr key={index}>
                                <td className="text-left py-1 border-0">{diff.path}</td>
                                <td className="text-left py-1 border-0">
                                  <p className="audience-audit-log-old-value-text rounded m-0">
                                    {formatText(diff.path, diff.oldValue)}
                                  </p>
                                </td>
                                <td className="text-left py-1 border-0">
                                  <p className="audience-audit-log-new-value-text rounded m-0">{formatText(diff.path, diff.newValue)}</p>
                                </td>
                                <td className="border-0" />
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      }
    </Fragment>
  )
}