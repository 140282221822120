import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { BatchOverviewType } from '../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes'
import { getCurrentDate } from '../../../components/BatchOverview/Details/BatchSetupDetails'

const initialState: BatchOverviewType = {
  batchAction: undefined,
  id: "",
  displayName: "",
  status: "Draft",
  clientCPI: 0,
  completeAsap: true,
  integrationPartners: [],
  launchDate: getCurrentDate(),
  targetEndDate: "",
  targeting: null,
  targetType: "",
  createdUtc: "",
  description: "",
  createdByName: "",
  lastModifiedUtc: "",
  lengthOfInterview: 0,
  quantity: 0,
  incidenceRate: 0,
  cpi: 0,
  currency: "",
  currencySymbol: "",
  market: { country: "", countryName: "", language: "", languageName: "", locale: "" },
  projectManagerEmail: "",
  gatekeeperUrl: "",
  gatekeeperTestUrl: "",
  etag: "",
  audienceId: "",
  projectId: "",
  sampleId: "",
  audienceInfo: null,
  sampleInfo: null
}

const batchDataSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    createNewBatch(_state, action: PayloadAction<{ batchAction: "new" }>) {
      const stateUpdate = { ...initialState, batchAction: action.payload.batchAction }
      return stateUpdate
    },

    setBatch(_state, action: PayloadAction<BatchOverviewType>) {
      return action.payload
    },
    editBatchAction(state, action: PayloadAction<"new" | "copy" | "edit">) {
      const stateUpdate = { ...state, batchAction: action.payload }
      return stateUpdate
    },
    editBatchStatus(state, action: PayloadAction<"Pending" | "Draft" | "Closed" | "Live" | "Reconciled" | "Cancelled" | "Archived">) {
      const stateUpdate = { ...state, status: action.payload }
      return stateUpdate
    },
    editBatchEtag(state, action: PayloadAction<string>) {
      const stateUpdate = { ...state, etag: action.payload }
      return stateUpdate
    },
  }
})

export const {
  createNewBatch,
  setBatch,
  editBatchAction,
  editBatchStatus,
  editBatchEtag
} = batchDataSlice.actions
export default batchDataSlice.reducer