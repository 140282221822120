import { z } from "zod";
import { ZodIsRequiredErrorMessage } from "../ZodCustomErrorMessages";

const surveyTypes = ["AdHoc", "Tracker", "Recontact"] as const;
const targetTypes = ["Completes", "Prescreens"] as const;
const currencyTypes = ["GBP", "USD", "AUD", "EUR"] as const;

export const audienceSchema = z.object({
  displayName: z.string().min(1, ZodIsRequiredErrorMessage("Name")),
  surveyType: z.enum(surveyTypes, ZodIsRequiredErrorMessage("Survey type")),
  country: z.string().min(1, ZodIsRequiredErrorMessage("Market")),
  language: z.string().min(1, ZodIsRequiredErrorMessage("Language")),
  surveyLiveUrl: z.string().url().min(1, ZodIsRequiredErrorMessage("Live URL")).optional().or(z.literal('')),
  surveyTestUrl: z.string().url().min(1, ZodIsRequiredErrorMessage("Test URL")).optional().or(z.literal('')),
  pii: z.boolean(),
  allowReentry: z.boolean().optional(),
  redirectCaptureParameter: z.string().optional(),
  uniqueUrlsLiveFileId: z.string(ZodIsRequiredErrorMessage("Batch URLs")).optional(),
  uniqueUrlsTestFileId: z.string(ZodIsRequiredErrorMessage("Batch test URLs")).optional(),
  uniqueUrlsWidParam: z.string().refine((value) => /^([A-Za-z]|[0-9]|_)+$/.test(value ?? ""), "Only Alphanumerical values and _ allowed").or(z.null()),
  useUniqueUrls: z.boolean(),
  integrationPartners: z.string().array().nullable(),
  exclusion: z.object({
    respondentStatuses: z.string().array().nullable(),
    exclusionDurationDays: z.number().min(1).nullable(),
    audienceIds: z.string().array().nullable()
  }).nullable().refine(data => {
    if (data === null) return true;
    if (data?.respondentStatuses?.length && data.audienceIds?.length && data.exclusionDurationDays) return true;
    if (data?.respondentStatuses === null && data.audienceIds === null) return true;
    if (data?.respondentStatuses?.length && data.audienceIds?.length && data.exclusionDurationDays === null) return true;

    if (data?.respondentStatuses === null && data.audienceIds === null && data.exclusionDurationDays) return false;
    if (data?.respondentStatuses === null && data.audienceIds?.length) return false;
    if (data?.respondentStatuses?.length && data.audienceIds === null) return false;
  }, "Values can't be empty for exclusions"),
  originalIdRecontactVariable: z.string().nullable(),
  walrIdRecontactVariable: z.string().nullable(),
  recontactsFileId: z.string().nullable(),
  currency: z.enum(currencyTypes, ZodIsRequiredErrorMessage("Currency")).optional(),
  targetType: z.enum(targetTypes, ZodIsRequiredErrorMessage("Target type")).optional(),
  launchDate: z.string().min(1, ZodIsRequiredErrorMessage("Start date")).optional(),
  targetEndDate: z.string().min(1, ZodIsRequiredErrorMessage("End date")).optional().optional(),
  quantity: z.number().min(1, ZodIsRequiredErrorMessage("Target completes *")).optional(),
  lengthOfInterview: z.number().min(1, ZodIsRequiredErrorMessage("Target completion LOI *")).optional(),
  incidenceRate: z.number().min(1, ZodIsRequiredErrorMessage("Target incidence rate *")).max(101, "IncidenceRate must be greater than 0 but less than 101").optional(),
  clientCPI: z.number().positive().or(z.literal(0)).optional(),
  cpi: z.number().positive(ZodIsRequiredErrorMessage("Sample CPI")).optional(),
  completeAsap: z.boolean().optional(),
})
