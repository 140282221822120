import React, { Fragment } from 'react';

import type { DropdownItem } from "../DropdownButton"
import { Icon } from '../../../shared/Icon/Icon';

interface Props {
  text?: string
  items?: TODO[]
  textField?: string
  onItemClick?: (e: TODO) => void
  renderItem?: (item: DropdownItem) => void
}

export const DropdownButtonRenderedItems = ({ text, items, textField, renderItem, onItemClick }: Props) => {
  let renderItems: TODO = null
  const itemTextField: string = textField ? textField : 'text'

  if (items && items.length > 0) {
    renderItems = React.Children.toArray(
      //@ts-ignore TODO
      items.map((item, key) => {
        if (item[itemTextField] === 'separator') {
          // display line between items
          return (
            <div
              style={{ height: '1px' }}
              className="w-100 my-1 bg-light"
            />
          )
        } if (renderItem) {
          // Display custom jsx for the items
          return renderItem({ ...item, key: key })
        } if ((item[itemTextField]?.includes('Delete') || item[itemTextField]?.includes('Disable')) && !item.ignoreDeleteDisable) {
          // Display Delete/Disable item with a separator above it
          return (
            <Fragment>
              <div
                style={{ height: '1px' }}
                className="w-100 my-1 bg-light" />
              <button
                type="button"
                className="dropdown-item text-danger px-3"
                disabled={item.disabled}
                onClick={onItemClick ? (e) => onItemClick({ ...e, item: item }) : () => { }}>
                <span>{item[itemTextField]}</span>
              </button>
            </Fragment>
          )
        }
        // Display default button
        return (
          <button
            key={key}
            type="button"
            className="d-flex align-items-center dropdown-item px-4"
            disabled={item.disabled}
            onClick={onItemClick ? (e) => onItemClick({ ...e, item: item }) : () => { }}
            title={item[itemTextField]}
          >
            <div className="d-flex align-items-center mw-100 gap-sm">
              {
                text &&

                <Icon type="checkmark" className={`fill-primary ${item[itemTextField] !== text ? 'invisible' : ''}`} />
              }
              <span className='text-truncate w-100'>{item[itemTextField]}</span>
            </div>
          </button>
        )
      })
    )
  }

  return renderItems
}

