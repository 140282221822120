import { z } from "zod";
import { ZodIsRequiredErrorMessage } from "../ZodCustomErrorMessages";

export const newSupplierSchema = z.object({
  name: z
    .string().min(1, ZodIsRequiredErrorMessage("Name")),
  completeUrl: z
    .string().url().min(1, ZodIsRequiredErrorMessage("Complete redirect")),
  quotaOutUrl: z
    .string().url().min(1, ZodIsRequiredErrorMessage("Over quota redirect")),
  qualityFailUrl: z
    .string().url().min(1, ZodIsRequiredErrorMessage("Quality termination redirect")),
  screenOutUrl: z
    .string().url().min(1, ZodIsRequiredErrorMessage("Screen out redirect")),
});