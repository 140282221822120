type Props = {
  searchTerm: string | null;
  selectedMarket: {
    name: string;
    isoCode: string;
  }
}

export const AudiencesNoProjects = ({ searchTerm, selectedMarket }: Props) => {
  const text = searchTerm ? searchTerm : selectedMarket.name;
  return (
    <div className="d-flex flex-column align-items-center justify-content-center gap-md my-3" style={{ height: "50svh" }}>
      <i className="fal fa-folder-open fa-2x text-muted" />
      <p className="text-muted m-0">
        No projects found for {text}
      </p>
    </div>
  )
}