import { type FormEvent, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchPostFile } from "../../../services/services";
import { UsedUniqueUrlsLinks } from "./UsedUniqueUrlsLinks";
import type { UniqueUrlsModalType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes";

type Props = {
  setUniqueUrlsModal: ({ show, type, isUniqueUrlEnabled }: UniqueUrlsModalType) => void;
  uniqueUrlsModal: UniqueUrlsModalType
  setUniqueUrlLiveFileId: (liveId: string) => void
  setUniqueUrlTestFileId: (testId: string) => void
}

export const UniqueUrlsModal = ({ setUniqueUrlsModal, uniqueUrlsModal, setUniqueUrlLiveFileId, setUniqueUrlTestFileId }: Props) => {
  const { audienceAction, projectId, sampleId } = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isCreatedAudience = uniqueUrlsModal.isUniqueUrlEnabled && audienceAction !== "new";

  const dispatch = useDispatch();

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const isTestFile = uniqueUrlsModal.type === "testUrl";
    setIsLoading(true);

    fetchPostFile(`au/a/projects/${projectId}/samples/${sampleId}/audiences/uploaduniqueurlfile?isTestFile=${isTestFile}`, token, formData)
      .then((res: TODO) => {
        if (res.ok === false) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Something went wrong. Please try again.' } });
          setIsLoading(false);
        } else {
          if (isTestFile) {
            setUniqueUrlTestFileId(res.fileId)
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: res.message } });
            setUniqueUrlsModal({ show: false, type: undefined, isUniqueUrlEnabled: uniqueUrlsModal.isUniqueUrlEnabled })
          } else {
            setUniqueUrlLiveFileId(res.fileId)
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: res.message } });
            setUniqueUrlsModal({ show: false, type: undefined, isUniqueUrlEnabled: uniqueUrlsModal.isUniqueUrlEnabled })
          }
          setIsLoading(false);
        }
      })
  }

  return (
    <Dialog width={"600px"} title={uniqueUrlsModal.type === "liveUrl" ? "Add live URLs" : "Add test URLs"} onClose={() => setUniqueUrlsModal({ show: false, type: undefined, isUniqueUrlEnabled: uniqueUrlsModal.isUniqueUrlEnabled })}>
      {
        isCreatedAudience &&
        <div className="px-4 py-2">
          <UsedUniqueUrlsLinks isTestUrl={uniqueUrlsModal.type === "testUrl"} />
        </div>
      }

      <form onSubmit={onFormSubmit} encType="multipart/form-data" className="d-flex flex-column gap-md">
        <div className="d-flex gap-md p-4">
          <label htmlFor="file" className='btn btn-primary'>Browse files</label>
          <input id="file" name="file" type="file" accept=".txt, .xlsx, .csv" onChange={(e) => setFileName(e.target.files ? e.target.files[0].name : "File")} hidden />
          <input name="fileId" placeholder="Select file" value={fileName} readOnly className="form-control h-32 pe-none" />
        </div>

        <DialogActionsBar>
          <button type='submit' className="btn btn-primary gap-md" disabled={isLoading}>
            Validate URLs

            {
              isLoading &&
              <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
            }
          </button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}