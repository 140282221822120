import { Icon } from '../../../shared/Icon/Icon'

export const ManageTarget = ({ toggleCustomAudienceModal }: { toggleCustomAudienceModal: () => void }) => {
  return (
    <article className='container-target-manage nav-board bg-white d-flex justify-content-center align-items-center overflow-auto flex-column gap-xxl'>
      <div className='content-container d-flex flex-column align-items-center justify-content-center gap-xl'>
        <div className='inner mb-8'>
          <div className='icon d-flex align-items-center justify-content-center'><Icon type="target-audience" className='target-icon' /></div>
          <div className='circle' />
          <div className='circle' />
          <div className='circle' />
          <div className='circle' />
        </div>

        <div className='d-flex flex-column align-items-center gap-md'>
          <p className='target-title stronger m-0' >Add and manage your targeting</p>
          <p className='target-text text-primary m-0' >This is where you'll add and manage your screening and quota questions. And build your own custom audience.</p>
        </div>
      </div>

      <div className='card-container d-flex w-100  justify-content-center'>
        <div className='card-container d-flex w-100 align-items-center justify-content-center' >
          <div className='target-card w-30 d-flex flex-column align-items-start bg-white justify-content-between p-6 gap-lg'>
            <div className='d-flex flex-column gap-md'>
              <h6 className='text-primary stronger m-0'>Custom audience</h6>
              <p className='text-primary text-muted m-0'>Build your own audience by utilizing numerous of targeting variables.</p>
            </div>

            <div className='d-flex gap-md'>
              <button onClick={() => toggleCustomAudienceModal()} className="btn dropdown-toggle text-white bg-primary strong" type="button">
                Add screening
              </button>

              {/* <button onClick={() => toggleCustomAudienceModal()} className="btn dropdown-toggle text-white bg-primary strong" type="button">
                Add quotas
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}