export const BatchOverviewTabs = ["Details", "Targeting", "Audit log"] as const;
export type BatchOverviewTabsType = (typeof BatchOverviewTabs)[number];

export const BatchTargeting = ["Custom", "None", "Existing", "NatRep"] as const;
export type BatchTargetingType = (typeof BatchTargeting)[number];

export const BatchStatus = ["Pending", "Live", "Closed", "Cancelled", "Reconciled", "Archived", "Draft"] as const;
export type BatchStatusType = (typeof BatchStatus)[number];

// "Pending" | "Draft" | "Closed" | "Live" | "Reconciled" | "Cancelled" | "Archived"

export type ScreeningAnswers = {
  id?: number,
  text?: string,
  start?: number,
  localizedTex?: string;
  end?: number
}

export type QuotaAnswers = {
  id?: number,
  text?: string,
  start?: number,
  end?: number
}

export type QuotaTargets = {
  id: number,
  targetCompletes: number,
  completes: number,
  completesRemaining: number,
  entrants: number,
  incidenceRate: number,
  answers: QuotaAnswers[]
}

export type Screening = {
  questionId: number,
  name: string,
  text: string,
  isInclude: boolean,
  answers: ScreeningAnswers[]
}
export type Quotas = {
  questionId: number,
  name: string,
  text: string,
  targets: QuotaTargets[]
}

export type Targeting = {
  type: BatchTargetingType;
  existingId?: string | null;
  screenings?: Screening[] | null;
  quotas?: Quotas[] | null;
}

export type Market = {
  country: string;
  countryName: string;
  language: string;
  languageName: string;
  locale: string;
}

export type AudienceInfo = {
  surveyTestUrl: string;
  surveyLiveUrl: string;
  displayName: string;
}

export type BatchOverviewType = {
  batchAction?: "new" | "copy" | "edit";
  id: string
  displayName: string
  status: BatchStatusType
  targetType: string;
  lengthOfInterview: number;
  quantity: number;
  incidenceRate: number;
  cpi: number;
  clientCPI: number;
  currency: string;
  currencySymbol: string;
  market: Market;
  targeting: Targeting | null;
  launchDate: string;
  targetEndDate: string;
  completeAsap: boolean;
  gatekeeperUrl: string | null;
  gatekeeperTestUrl: string | null;
  integrationPartners: string[];
  etag: string | null;
  createdUtc: string
  description: string | null
  createdByName: string | null
  lastModifiedUtc: string | null
  projectManagerEmail: string | null;
  audienceId: string;
  projectId: string;
  sampleId: string;
  audienceInfo: AudienceInfo | null;
  sampleInfo: { displayName: string } | null;
}