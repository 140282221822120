import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { FullHeightSpinner } from '../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { returnSurveyOptionsByType } from '../returnSurveyOptionsByType/returnSurveyOptionsByType';
import { fetchGetJson as getSurveyStructure } from '../../../../../../services/services';

export default ({ item, optionValues, setOptionValues, handleClose, onSaveSectionOptions }) => {
  const listItemValueType = item.attributes.loop === "" || item.attributes.loop === undefined ? "none" : Number.isNaN(Number.parseInt(item.attributes.loop || "")) ? "list" : "number";
  const dispatch = useDispatch();
  const [listItemsDropdown, setListItemsDropdown] = useState([])
  const [listItemValue, setListItemValue] = useState({
    list: listItemValueType === "list" ? item.attributes.loop : "Choose a list",
    number: listItemValueType === "number" ? item.attributes.loop : "",
  })
  const [loopType, setLoopType] = useState(listItemValueType)
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useSelector((state) => state.tokenStateReducer);
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer))

  useEffect(() => {
    const lists = theData.originalData.lists
    const array = []
    lists.map(el => {
      return array.push({ text: el.attributes.id })
    })
    setListItemsDropdown(array)
  }, [theData.originalData.lists])

  const onValueChange = (e, type, option) => {
    if (type === 'string') {
      setOptionValues({ ...optionValues, [option.id]: e.value })

    } else if (type === 'dropdown') {
      setOptionValues({ ...optionValues, [option.id]: e.value.name })
    }
    else if (type === 'css') {
      if (e.value.id !== 'none') {
        setOptionValues({ ...optionValues, [option.id]: e.value.id })
      } else {
        const selectedValues = optionValues
        if (selectedValues.css) {
          delete selectedValues.css
          setOptionValues(selectedValues)
        }
      }
    } else if (type === 'loop') {
      setListItemValue({ ...listItemValue, [loopType]: e.item.text })
      setOptionValues({ ...optionValues, [option.id]: e.item.text })
    }
  }

  const showFilterBuilder = (optionId) => {
    getSurveyStructure(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/structure`, token)
      .then(res => {
        dispatch({
          type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
            display: true,
            filterData: res,
            filterBuilderOptionId: optionId,
            combineFilterData: item.metadata?.filterData ? item.metadata.filterData : []
          }
        })
      })
  }

  const updateLoopType = (val) => {
    setLoopType(val)
    if (val === "none") {
      setOptionValues({ ...optionValues, loop: "" })
    }
  }

  const themes = [];
  const selectedElement = item;
  const isSectionOptionsDialogOpen = true;
  const emailProviders = [];
  const getEmailProviders = () => { };
  const options = returnSurveyOptionsByType(theData, selectedElement, optionValues, onValueChange, showFilterBuilder, themes, listItemsDropdown, listItemValue, emailProviders, getEmailProviders, isSectionOptionsDialogOpen, loopType, (loopType) => updateLoopType(loopType))

  const onSubmitHandler = (optionValues) => {
    if (loopType === "number") {
      const loopValue = Number.parseInt(optionValues.loop);
      if (Number.isNaN(loopValue)) {
        setErrorMessage("Loop section value must be a number");
      } else if (loopValue === 0) {
        setErrorMessage("Loop section value must be greater than 0");
      } else {
        setErrorMessage("");
        onSaveSectionOptions(optionValues)
      }
    } else {
      onSaveSectionOptions(optionValues)
    }
  }

  return (
    <Dialog className='section-options-dialog' title="Section options" width="600px" height={"600px"} onClose={handleClose}>
      {
        options ?
          <div className="p-4 d-flex flex-column gap-md">
            {options.map(option => option)}
          </div>
          :
          <FullHeightSpinner />
      }
      {
        errorMessage &&
        <div role='alert' className="alert alert-danger mx-4">{errorMessage}</div>
      }
      <DialogActionsBar>
        <button type='button' className="k-button btn-secondary" onClick={handleClose}>Cancel</button>
        <button type='button' className="k-button btn-primary" onClick={() => onSubmitHandler(optionValues)}>Save options</button>
      </DialogActionsBar>
    </Dialog>
  )
}