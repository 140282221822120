import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

type UpdatedMarket = { name: string, isoCode: string } | { name: "", isoCode: "" };

interface Props {
  onHide: () => void,
  updatedMarket: UpdatedMarket;
  setSelectedlang: (selectedLang: { name: "", isoCode: "" }) => void;
  setSelectedMarket: (updatedMarket: UpdatedMarket) => void;
}

export default function UpdateMarketModal({ onHide, updatedMarket, setSelectedlang, setSelectedMarket }: Props) {
  const handleUpdateMarket = () => {
    setSelectedlang({ name: "", isoCode: "" });
    setSelectedMarket(updatedMarket)
    onHide()
  }

  return (
    <Dialog title={"Edit Market"} onClose={onHide}>
      <div className='p-4'>
        <p className='m-0'>
          Making changes to the market will result in resetting all targeting settings.
        </p>
        <p className='m-0'>
          <u>Are you sure you want to proceed?</u>
        </p>
      </div>
      <DialogActionsBar>
        <button
          type='button'
          className="btn btn-secondary"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          type='button'
          className="btn btn-primary"
          onClick={handleUpdateMarket}
        >
          Continue
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}