import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { Input } from "@progress/kendo-react-inputs";
import { type FormEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchDeleteJson } from "../../../services/services";
import type { Batch } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { useParams } from "react-router-dom";

type Props = {
  setShowDeleteModal: (show: boolean) => void;
  batch: Batch;
  batches: Batch[];
  setBatches: (batches: Batch[]) => void;
}

export const DeleteModal = ({ setShowDeleteModal, batch, batches, setBatches }: Props) => {
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [errorMessage, setErrorMessage] = useState("")
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const removeItemFromList = () => {
    const filteredBatches = batches.filter((item) => item.id !== batch.id);
    setBatches(filteredBatches);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    fetchDeleteJson(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batch?.id}`, token)
      .then((res: TODO) => {
        setIsLoading(false);
        if (res && res.status === 204) {
          removeItemFromList();
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The batch has been deleted successfully" } })
          setShowDeleteModal(false);
        } else if (res) {
          setErrorMessage(res.message ? res.message : res);
        }
      })
  }


  return (
    <Dialog width={500} height={400} title={`Delete ${batch.displayName}`} onClose={() => setShowDeleteModal(false)}>
      <form className="d-flex flex-column justify-content-between h-100 gap-md px-2" onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column gap-md py-4">
          <p>
            Confirm the deletion of the batch by typing the batch <strong>name</strong> in the input field
          </p>
          <Input
            required
            onChange={e => setName(typeof e.target.value === "string" ? e.target.value : "")}
            value={name}
            placeholder="Enter batch name"
            name="name"
            className="w-100" />
          {
            errorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage} />
            </div>
          }
        </div>

        <DialogActionsBar>
          <button type='button' className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
          <button
            type='submit'
            className="btn btn-primary"
            disabled={isLoading}>
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm mr-2" />
            }
            Delete
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}