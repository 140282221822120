import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import type { RootState } from '../../../store/reducers/rootReducer';
import { ManageTarget } from './ManageTarget/ManageTarget';
import { TagretingQuota } from './Quota/Quota'
import { TagretingScreening } from './Screening/Screening';
import { CustomTargetingModal } from '../Modals/CustomTargetingModal/CustomTargetingModal';

export const BatchTargeting = () => {
  const [showCustomAudienceModal, setShowCustomAudienceModal] = useState<{ show: boolean, editMode: boolean }>({ show: false, editMode: false })

  const data = useSelector((state: RootState) => state.batchDataReducer);

  return (
    <Fragment>
      {showCustomAudienceModal.show &&
        <CustomTargetingModal
          customAudienceModalValues={data}
          handleModalVisibility={setShowCustomAudienceModal}
          editMode={showCustomAudienceModal.editMode}
        />
      }

      <div className='board-container d-flex justify-content-center flex-column w-100 gap-md'>
        {
          (data?.targeting?.screenings && data?.targeting?.quotas) ?

            <Fragment>
              <TagretingScreening screenings={data.targeting?.screenings} setShowCustomAudienceModal={setShowCustomAudienceModal} />

              <TagretingQuota quotas={data.targeting?.quotas} audience={data} />
            </Fragment>
            :
            <ManageTarget toggleCustomAudienceModal={() => setShowCustomAudienceModal({ show: true, editMode: false })} />
        }
      </div>
    </Fragment>
  )
}