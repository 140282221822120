import { useSelector } from "react-redux";
import type { RootState } from "../../../store/reducers/rootReducer";

export const BatchAudienceInfo = () => {
  const { audienceInfo, market } = useSelector((state: RootState) => state.batchDataReducer);
  return (
    <div className='d-flex justify-content-center flex-column w-100'>
      <div className='board-content-container'>
        <div className='row row-col-2 px-1'>
          <div className="details-input d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong details-input-text text-primary'>
              Audience market
            </p>
            <input className='form-control' type="text" readOnly defaultValue={market.countryName} />
          </div>

          <div className="details-input d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong details-input-text text-primary'>
              Audience language
            </p>
            <input className='form-control' type="text" readOnly defaultValue={market.languageName} />
          </div>

          <div className="details-input d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong details-input-text text-primary'>
              Audience live link
            </p>
            <input className='form-control' type="text" readOnly defaultValue={audienceInfo?.surveyLiveUrl} />
          </div>

          <div className="details-input d-flex flex-column w-50 gap-sm">
            <p className='p-0 m-0 strong details-input-text text-primary'>
              Audience test link
            </p>
            <input className='form-control' type="text" readOnly defaultValue={audienceInfo?.surveyTestUrl} />
          </div>
        </div>
      </div>
    </div>
  )
}