import type { Screening } from "../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";


type Props = {
  screenings?: Screening[] | null;
  setShowCustomAudienceModal: ({ show, editMode }: { show: boolean, editMode: boolean }) => void;
}

export const TagretingScreening = ({ screenings, setShowCustomAudienceModal }: Props) => {
  return (
    <article className='nav-board d-flex flex-column bg-white answer-layout p-5 gap-md'>
      <p className="innerboard-header d-flex align-items-center stronger h-32 m-0">Screening</p>
      {screenings?.length && screenings.length < 1 ?
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <tbody>
              <tr>
                <td className='d-flex justify-content-center p-4 w-100 m-0 text-muted'>No data provided</td>
              </tr>
            </tbody>
          </table>
        </div>
        :
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <thead >
              <tr>
                <th className='text-muted font-weight-normal' scope="col">Question</th>
                <th className='text-muted font-weight-normal' scope="col">Answer options</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {screenings?.map((screening) =>
              (
                <tr key={screening.questionId}>
                  <th scope="row">
                    <div className='d-flex align-items-center h-100'>
                      <span>
                        <p className='text-indigo-dark small strong p-0 m-0' >{screening.name}</p>
                        <p className='regular strong text-primary p-0 m-0' >{screening.text}</p>
                      </span>
                    </div>
                  </th>
                  <td className='text-left align-middle' width={"40%"} >
                    <div className='d-flex flex-row flex-wrap pb-2'>
                      {screening.answers.map((answer) => (
                        <p key={answer.id} className='text-nowrap text-truncate small strong badge px-2 py-1 mb-1 mr-1' >{answer.text ? answer.text : `${answer.start} to ${answer.end}`}</p>
                      ))}
                    </div>
                  </td>
                  <td className='text-right' width={"10%"} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }

      <div className='d-flex justify-content-end'>
        <button type='button' className='btn btn-primary' onClick={() => setShowCustomAudienceModal({ show: true, editMode: true })}>
          Edit screening
        </button>
      </div>
    </article>
  )
}