import { Fragment, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { navbarItems } from './navbarItems';
import { SideBar } from '../SideBar/SideBar';
import { Icon } from '../Icon/Icon';
import { InboxDrawer } from '../InboxDrawer/InboxDrawer';
import { parseJwt } from '../helpers/decodeJWT/decodeJWT';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import { UserDropdownItem } from './UserDropdownItem/UserDropdownItem';
import returnUsersPlan from '../../Billing/helpers/returnUsersPlan/returnUsersPlan';
import { HelpCentreDropdownItem } from './HelpCentreDropdownItem/HelpCentreDropdownItem';
import returnMenuItemsByRole from '../helpers/returnMenuItemsByRole/returnMenuItemsByRole';
import { fetchGetJson as getInboxItems, fetchGetJson as getUser, rsAppUrl } from '../../../services/services';


export const Navbar = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { isLoading, user, logout } = useAuth0();
  const [userData, setUserData] = useState({ customerName: null, role: null, roleId: null, plan: null, subscriptionPlan: null, enterpriseSettings: null })
  const [showActivity, setShowActivity] = useState(false)
  const [inboxLength, setInboxLength] = useState(0)
  const { shouldUpdateNavbarData } = useSelector((theState) => theState.adminReducer)
  const [canSeeBackOffice, setCanSeeBackOffice] = useState(false)
  const { inboxNotificationBadge } = useSelector((theState) => theState.inboxNotificationStateReducer)
  const { token } = useSelector((state) => state.tokenStateReducer);

  const FreshworksWidget = window.FreshworksWidget
  if (FreshworksWidget) {
    FreshworksWidget('hide', 'launcher');
  }

  const updateFreshworksWidget = (type, id) => {
    if (type === 'openArticle' && id) {
      FreshworksWidget('open', 'article', { id: id })
    } else if (type === 'openTicketForm') {
      FreshworksWidget('open', 'ticketForm')
    }
  }

  const userDropdownItems = [
    { text: 'Main user info', id: 'user-info' },
    { text: 'separator' },
    { text: 'Company name', id: 'company' },
    { text: 'separator' },
    ...returnMenuItemsByRole(userData.role, userData.enterpriseSettings, canSeeBackOffice)
  ]

  const helpCenterItems = [
    { text: 'Primary', id: 'primary' },
    { text: 'separator' },
    { text: 'Secondary', id: 'secondary' },
    { text: 'separator' },
    { text: 'Other', id: 'other' }
  ]

  useEffect(() => {
    if (token && showActivity === false) {
      getInboxItems("inboxes", token)
        .then((res) => {
          if (res && !res.error) {
            if (Array.isArray(res)) {
              const updatedInboxLength = res.filter((el) => el.isRead === false).length
              setInboxLength(updatedInboxLength)
              if (updatedInboxLength === 0) {
                dispatch({ type: 'UPDATE_INBOX_NOTIFICATIONS_BADGE', payload: false })
              }
            }
          }
        })
    }
  }, [token, showActivity, dispatch])

  useEffect(() => {
    if (user && token) {
      if (shouldUpdateNavbarData) {
        const parsedToken = parseJwt(token);
        dispatch({ type: 'SET_SHOULD_UPDATE_NAVBAR_DATA', payload: false })
        getUser(`users/${parsedToken[`${rsAppUrl}/userid`]}/extended`, token)
          .then((res) => {
            if (res && !res.error) {
              // if (res.isSubAccount) {
              //   setHeaderLogo(res.headerLogoUrl ? res.headerLogoUrl : '')
              // }
              const newPlan = returnUsersPlan(res.subscriptionPlan, res.subscriptionStatus)
              setUserData({ originalCustomerName: res.originalCustomerName, customerName: res.customerName, role: res.userRole, roleId: res.subscriptionId, plan: newPlan, subscriptionPlan: res.subscriptionPlan, enterpriseSettings: res.enterpriseSettings })
            }
          })
        setCanSeeBackOffice(parsedToken[`${rsAppUrl}/backOffice`])
        dispatch({ type: 'SET_INITIAL_BREADCRUMB_DATA' })
      }
    }
  }, [shouldUpdateNavbarData, user, dispatch, token])

  const navItemList =
    userData.enterpriseSettings &&
    navbarItems.map((item) => (
      <li key={item.title} className='header-nav-item'>
        {
          item.title === 'Activity' ?
            <div onClick={() => setShowActivity(true)} className="header-nav-link">
              <button type='button' className="btn p-0 border-0 activity" id="activity" aria-label="activity">
                {inboxLength > 0 || inboxNotificationBadge ? <div className="notification-dot" /> : null}
                <Icon className='header-nav-icon' type={`${item.icon}`} />
              </button>
            </div>
            :
            item.title === "Audiences" ?
              userData?.enterpriseSettings?.hasAudienceAccess &&
              <NavLink
                className={"header-nav-link d-flex justify-content-start"}
                activeClassName="active"
                to={item.route}
                exact={item.route === '/'}
              >
                <Icon className='header-nav-icon' type={`${item.icon}`} />
                <p className='sr-only'>{item.title}</p>
              </NavLink> :
              <NavLink
                className={"header-nav-link d-flex justify-content-start"}
                activeClassName="active"
                to={item.route}
                exact={item.route === '/'}
              >
                <Icon className='header-nav-icon' type={`${item.icon}`} />
                <p className='sr-only'>{item.title}</p>
              </NavLink>
        }
        <span className='header-nav-tooltip'>{`${item.title}`}</span>
      </li>
    ));

  const onUserDropdownItemClick = (id, route) => {
    if (id === 'logout') {
      localStorage.clear()
      logout({ logoutParams: { returnTo: window.location.origin } })
    } else {
      history.push(route)
    }
  }

  if ((!isLoading && !user) || shouldUpdateNavbarData) {
    return null
  }
  if (history.location.pathname.includes('analyze') || history.location.pathname.includes('workflow') || history.location.pathname.includes('survey-builder') || history.location.pathname.includes('pinboards')) {
    return null
  }

  return (
    <Fragment>
      {
        showActivity &&
        <div className='sidebar-activity'>
          <SideBar width="400px" onHide={() => setShowActivity(false)}>
            <InboxDrawer />
          </SideBar>
        </div>
      }
      <header>
        <nav>
          <div className='menu'>
            <div className='logo'>
              <Link to="/">
                <Icon className='walr' type="walr" />
                <p className='sr-only'>Home</p>
              </Link>
            </div>
            <ul className='header-nav-items'>
              {navItemList}
            </ul>
            <div className='user-menu'>
              <ul>
                <li className='header-nav-item help'>
                  <DropdownButton
                    hideChevron={true}
                    className='header-nav-link d-flex justify-content-start'
                    parentClass="user-dropdown help-center-dropdown"
                    items={helpCenterItems}
                    renderItem={(item) =>
                      <HelpCentreDropdownItem
                        id={item.id}
                        updateFreshworksWidget={updateFreshworksWidget}
                      />
                    }
                    renderMainButton={() => (
                      <Icon className='header-nav-icon' type='nav-help' />
                    )}
                  />
                  <span className='header-nav-tooltip'>Help center</span>
                </li>
                <li className='header-nav-item user'>
                  <DropdownButton
                    hideChevron={true}
                    className='header-nav-link d-flex justify-content-start'
                    parentClass="user-dropdown"
                    items={userDropdownItems}
                    renderItem={(item) =>
                      <UserDropdownItem
                        id={item.id}
                        text={item.text}
                        route={item.route}
                        user={{ ...user, ...userData }}
                        onUserDropdownItemClick={onUserDropdownItemClick}
                      />
                    }
                    renderMainButton={() => (
                      <span className='user-info'>{user.given_name[0]}</span>
                    )}
                  />
                  <span className='header-nav-tooltip'>User menu</span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </Fragment >
  );
};
