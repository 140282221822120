import { type FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { useHistory, useParams } from 'react-router-dom'

import type { RootState } from '../../../store/reducers/rootReducer';
import { BatchSetupDetails } from './BatchSetupDetails';
import { Icon } from '../../shared/Icon/Icon';
import { type ValidationError, handleZodValidation } from '../../shared/Zod/handleZodValidation';
import { formatFormData } from './formatFormData';
import { batchSchema } from '../../shared/Zod/ZodSchemas/batchSchema';
import { setBatch } from '../../../store/reducers/batchReducer/batchReducer';
import type { BatchOverviewType } from '../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes';
import { BatchTargeting } from '../Targeting/BatchTargeting';
import { fetchPostJsonData, fetchPutJsonData } from '../../../services/services';

export const BatchDetails = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { etag, batchAction, status, projectId, sampleId, audienceId, targeting } = useSelector((state: RootState) => state.batchDataReducer);
  const [errors, setErrors] = useState<ValidationError<typeof batchSchema>>();
  const [isLoading, setIsLoading] = useState(false);

  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const isStatusClosedOrReconciled = status === "Closed" || status === "Reconciled";

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    setErrors({});
    event.preventDefault();
    const formData = Object.fromEntries(new FormData(event.currentTarget));

    const formatedFormData = formatFormData(formData, etag, targeting);

    handleZodValidation({
      onError: setErrors,
      data: formatedFormData,
      onSuccess: () => {
        setIsLoading(true);
        if (batchAction !== 'edit') {
          fetchPostJsonData<{ batchId: string }>(`au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches`, token, formatedFormData)
            .then((res) => {
              setIsLoading(false)
              history.replace({ pathname: `/projects/${projectId}/samples/${sampleId}/audience/${audienceId}/batch/${res.batchId}` });
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Batch copied' } });
            }).catch((error) => {
              setIsLoading(false)
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
            })
        }
        if (params.audienceId && params.batchId && params.projectId && params.sampleId) {
          fetchPutJsonData<BatchOverviewType>(`au/a/projects/${params.projectId}/samples/${params.sampleId}/audiences/${params.audienceId}/batches/${params.batchId}`, token, formatedFormData)
            .then((res) => {
              setIsLoading(false)
              dispatch(setBatch({ ...res, batchAction: "edit" }))
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Batch updated successfully' } })
            }).catch((error) => {
              setIsLoading(false)
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
            })
        }
      },
      schema: batchSchema,
    });
  }

  return (
    <form onSubmit={onFormSubmit} className='d-flex flex-column gap-lg'>
      <BatchSetupDetails errors={errors} />

      {batchAction === "copy" && targeting && <BatchTargeting />}

      <footer className='p-4 bg-white'>
        <div className='d-flex justify-content-end align-items-center board-container'>
          <Tooltip anchorElement="target" position="top">
            <span title='All fields marked with an asterisk (*) must be completed, as they are mandatory.' className='btn btn-shadow p-1 d-flex align-items-center mr-2'>
              <Icon type='info-tooltip' className='pe-none' />
            </span>
          </Tooltip>
          <button type='submit' disabled={isStatusClosedOrReconciled || isLoading} className='btn btn-primary h-32 strong'>
            Save changes
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
            }
          </button>
        </div>
      </footer>
    </form>
  )
}